<template lang="pug">
svg(width="80" height="162" viewbox="0 0 80 162" fill="none" xmlns="http://www.w3.org/2000/svg")
  rect(x="1.5" y="0.5" width="77" height="161" rx="11.5" fill="#252626")
  g(clip-path="url(#clip0_529_46870)")
    rect(x="5" y="4" width="70" height="154" rx="8" fill="white")
    rect.mobile-position-area(
      @click="select('top')"
      :class="selectedClass('top')"
      x="5.125"
      y="4.125"
      width="71.75"
      height="36.75"
      fill="white"
    )
    rect(x="5.125" y="4.125" width="71.75" height="36.75" stroke="#313233" stroke-width="0.25")
    rect.mobile-position-area(
      @click="select('left-center')"
      :class="selectedClass('left-center')"
      x="5.125"
      y="41.125"
      width="35.75"
      height="79.75"
      fill="white"
    )
    rect(x="5.125" y="41.125" width="35.75" height="79.75" stroke="#313233" stroke-width="0.25")
    rect.mobile-position-area(
      @click="select('right-center')"
      :class="selectedClass('right-center')"
      x="41.125"
      y="41.125"
      width="35.75"
      height="79.75"
      fill="white"
    )
    rect(x="41.125" y="41.125" width="35.75" height="79.75" stroke="#313233" stroke-width="0.25")
    rect.mobile-position-area(
      @click="select('bottom')"
      :class="selectedClass('bottom')"
      x="5.125"
      y="121.125"
      width="71.75"
      height="36.75"
      fill="white"
    )
    rect(x="5.125" y="121.125" width="71.75" height="36.75" stroke="#313233" stroke-width="0.25")
  rect(x="5.25" y="4.25" width="69.5" height="153.5" rx="7.75" stroke="#313233" stroke-width="0.5")
  rect(x="1.5" y="0.5" width="77" height="161" rx="11.5" stroke="#585A5B")
  rect(y="21" width="1" height="6" fill="#252626")
  rect(y="39" width="1" height="11" fill="#252626")
  rect(x="79" y="43" width="1" height="18" fill="#252626")
  path(
    d="M20.7525 4.61186C20.3644 2.74892 21.7865 1 23.6894 1H56.3106C58.2135 1 59.6356 2.74892 59.2475 4.61186L58.8309 6.61186C58.541 8.00302 57.3149 9 55.8939 9H24.1061C22.6851 9 21.459 8.00302 21.1691 6.61186L20.7525 4.61186Z"
    fill="#252626"
  )
  rect(y="52" width="1" height="11" fill="#252626")
  rect(x="45" y="4" width="1" height="10" rx="0.5" transform="rotate(90 45 4)" fill="black")
  rect(x="48" y="4" width="1" height="2" rx="0.5" transform="rotate(90 48 4)" fill="black")
  defs
    clipPath#clip0_529_46870
      rect(x="5" y="4" width="70" height="154" rx="8" fill="white")
</template>
<script>
  import { mapMutations, mapGetters } from 'vuex';
  import itemMixin from '../../mixins/item';

  export default {
    mixins: [itemMixin],
    computed: {
      ...mapGetters(['nestedAccess']),
      position: {
        get() {
          return this.nestedAccess('selectedPage.data.mobilePosition');
        },
        set(v) {
          this.setValueOf('selectedPage.data.mobilePosition', v);
        },
      },
    },

    methods: {
      ...mapMutations(['updateStyle']),
      select(position) {
        this.position = position;
      },
      selectedClass(position) {
        return this.position === position ? 'selected' : '';
      },
    },
  };
</script>
<style lang="sass">
  .mobile-position-area
    cursor: pointer
    &.selected
        fill: var(--brand-primary-color)
</style>
